import { useEffect, useState } from "react"
import apiEndpoint from "../../test/config";

function SvetiSava() {
  let query = `{
        pages (where: {name: "Pocetna"} ){
          edges {
            node {
              pocetna {
                horizontalnaKutija1 {
                  naslov
                  tekst
                  background {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }`
  const [Podaci, setData] = useState({});
  const [ucitano, setUcitano] = useState(false);
  const dataToKutija = (jsonData) => {
    jsonData = jsonData['pages']['edges'][0]['node']['pocetna']['horizontalnaKutija1']
    let Kutija = {
      naslov: jsonData['naslov'],
      tekst: jsonData['tekst'],
      slika: jsonData['background']['sourceUrl'],
    }
    setData(Kutija)
    setUcitano(true)
  }
  // let Test = {
  //   naslov: 'Свети Сава и млад човек',
  //   tekst: '<p>Неки млад човек пошао у свет да пронађе добро.<br />Путовао, путовао, па га сусрeтне Свети Сава.<br />-Куда идеш пријатељу? - упита га Свети Сава.<br />-Светитељу, тражим добро по свету. - одговори му младић.<br />-Је си ли га негде нашао?<br />-Е, богами, светитељу нисам још.<br />-Тако га, синко, нећеш ни наћи, него се загледај у себе.<br />Пронађи у себи добро, у ономе што радиш и у ономе што мислиш.<br />Онда добро понуди другима, па ће га и други теби давати.</p><p>Младић се захвали, врати се кући и учини како му светитељ рече и би добро и њему и онима око њега.</p>',
  //   slika: 'https://gimnazijasvetisava.edu.rs/wp-content/uploads/2023/07/db12dc77c6bd56a95e741f6d385692ee.jpeg'
  // }
  // const BackupText = () => {
  //   setData(Test);
  // }
  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => dataToKutija(data['data']))

  }, [])

  return <>
    {ucitano &&
      <div className="sveti-sava-wrapper">
        <div className="data">
          <h1 dangerouslySetInnerHTML={{ __html: Podaci['naslov'].split('\r\n').join("<br>") }}></h1>
          <div className="tekst" dangerouslySetInnerHTML={{ __html: Podaci['tekst'] }}></div>
        </div>
        <div className='slika' style={{ backgroundImage: `url(${Podaci['slika']})` }}></div>
      </div>
    }
  </>
}

export default SvetiSava