import { useEffect, useState } from "react"
import apiEndpoint from "../../test/config";

function DobreVibracije() {
    const n = 13;
    let kutijeQuery = (n) => {
        let q = ''
        for (let i = 1; i <= n; i++) {
            q += `kutija${i} {\ntekst\nlink {\nurl\n}\nboja\nslika {\nsourceUrl\n}\n}\n`
        }
        return q
    }

    let query = `{
        pages(where: {name: "Pocetna"}) {
            edges {
                node {
                    pocetna {
                        dobrevibracije {
                            ${kutijeQuery(n)}
                        }
                    }
                }
            }
        }
    }`

    const dataToVesti = (data) => {
        // console.log(data)
        let kutije = []
        for (let i = 1; i <= n; i++) {
            let kutija = data[`kutija${i}`]
            kutije.push({
                tekst: kutija['tekst'],
                link: kutija['link'] == null ? null : kutija['link']['url'],
                slika: kutija['boja'] == null ? kutija['slika']['sourceUrl'] : null,
                boja: kutija['boja'] == null ? null : kutija['boja']
            })
        }
        setKutije(kutije)
        setUcitano(true)
    }
    const [kutije, setKutije] = useState([])
    const [ucitano, setUcitano] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => dataToVesti(data['data']['pages']['edges'][0]['node']['pocetna']['dobrevibracije']));
    }, []);

    // console.log(query)

    return <>
        <div className="dobre-vib-wrapper">
            <div className="naslov">
                <h1>ШИРИ</h1>
                <h2>Добре Вибрације!</h2>
            </div>
            <div className="dobre-vib">
                <div className="content">
                    <div className="isp-linije"></div>
                    <div className="kutije">
                        {ucitano && kutije.map((kutija, index) => {
                            return <div className="kutija" id={`k${index + 1}`} style={{ background: `${kutija['boja']}` }}>
                                {kutija['slika'] != null && <img src={kutija['slika']}></img>}
                                <p>{kutija['tekst']}</p>
                                {kutija['link'] != null && <a href={kutija['link']}></a>}
                                <h1>{index + 1}</h1>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className="kockice">
                <div id='k1'></div>
                <div id='k2'></div>
                <div id='k3'></div>
                <div id='k4'></div>
                <div id='k5'></div>
                <div id='k6'></div>
                <div id='k8'></div>
                <div id='k7'></div>
                <div id='k9'></div>
                <div id='k10'></div>
                <div id='k11'></div>
                <div id='k12'></div>
                <div id='k13'></div>
                <div id='k14'></div>
            </div>
        </div>
    </>
}

export default DobreVibracije