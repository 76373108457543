import { Link } from "react-router-dom"

function TileFlip({ naslov, ikonica, boja: pozadina, poruka, link, width = '100%', height = '100%', bojaNaslova, bojaIvice }) {
    let divStyle = {
        outline: `solid 2px`,
        outlineColor: bojaIvice,
        background: pozadina
    }
    return <div className="flipbox" style={{ width: width, height: height }}>
        <Link to={link} className="tile-flip">
            <div className="back" style={{ background: pozadina }}>
                <div className="tile-flip-poruka" dangerouslySetInnerHTML={{ __html: poruka }}></div>
            </div>
            <div style={divStyle} className="front">
                <p style={{ color: bojaNaslova }} className="tile-flip-naslov">{naslov}</p>
                <img src={ikonica}></img>
            </div>

        </Link>
    </div>
}

export default TileFlip