import '../../style.css';
import { useEffect, useState } from 'react';
import apiEndpoint from '../../test/config';
import { Link } from 'react-router-dom';

function Upis() {
  let query = `{
    pages(where: {name: "Pocetna"}) {
      edges {
        node {
          pocetna {
            infokutija {
              naslov
              saznajvise {
                url
                title
                target
              }
              tekstkutija
              slika {
                sourceUrl
              }
              }
            }
          }
        }
      }
    }`;

  const [data, setData] = useState({});

  const jsonToData = (jsonData) => {
    let infokutija = jsonData['pages']['edges'][0]['node']['pocetna']['infokutija'];
    // let linkovi1 = []
    // for (let i = 1; i <= 2; i++) {
    //   let urlSlika = {
    //     link: infokutija['linkovi'][`kutija${i}`]['link']['url'],
    //     slika: infokutija['linkovi'][`kutija${i}`]['slika']['sourceUrl'],
    //   }
    //   linkovi1.push(urlSlika);
    // }
    // console.log(infokutija)
    let Data = {
      naslov: infokutija['naslov'],
      tekst: infokutija['tekstkutija'],
      slika: infokutija['slika']['sourceUrl'],
      vise: infokutija['saznajvise']['url'],
      //linkovi: linkovi1
    }
    setData(Data);
  }

  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => jsonToData(data['data']));
  }, []);


  return <>
    <div className="upis-polje">
      <div className='upis-grid'>
        <h1 className='naslov'>{data['naslov']}</h1>
        <div className="hl"></div>
        <div className='slika'>
          <img src={data['slika']} width={'60%'}></img>
        </div>
        <div className="tekst" dangerouslySetInnerHTML={{ __html: data['tekst'] }}></div>
        <Link className="link" to={data['vise']}>Више о упису...</Link>
      </div>
    </div>
  </>;
}

export default Upis;