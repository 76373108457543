import { Link } from "react-router-dom";
import kamera from "../../res/images/kamera2.png"
import { useEffect, useState } from "react";
import apiEndpoint from "../../test/config";

const slika = "https://picsum.photos/1920/1080"
const querryGen = (id) => {
  return `{
          fotoGalerijaBy(id: "${id}") {
              naslov
              glavnaSlika {
                  sourceUrl
              }
          }
      }`;
};

const fetchGraphqlData = async (id) => {
  try {
    const response = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: querryGen(id),
      }),
    });

    const result = await response.json();
    let data = result['data']['fotoGalerijaBy']
    return {
      id: id,
      naslov: data['naslov'],
      slika: data['glavnaSlika']['sourceUrl']
    };

  } catch (error) {
    console.error(`Error fetching data for ID ${id}:`, error);
    return null;
  }
};


function Galerija2() {
  const query = `
    {
        pages(where: {name: "Pocetna"}) {
          nodes {
            pocetna {
              galerija {
                kutijaslike {
                  naslov
                  galerija {
                    ... on FotoGalerija {
                      id
                    }
                  }
                  link {
                    url
                  }
                  slika {
                    sourceUrl
                  }
                }
                kutijaslike2 {
                  naslov
                  galerija {
                    ... on FotoGalerija {
                      id
                    }
                  }
                  link {
                    url
                  }
                  slika {
                    sourceUrl
                  }
                }
                kutijaslike3 {
                  naslov
                  galerija {
                    ... on FotoGalerija {
                      id
                    }
                  }
                  link {
                    url
                  }
                  slika {
                    sourceUrl
                  }
                }
                kutijaslike4 {
                  naslov
                  galerija {
                    ... on FotoGalerija {
                      id
                    }
                  }
                  link {
                    url
                  }
                  slika {
                    sourceUrl
                  }
                }
                kutijaslike5 {
                  naslov
                  galerija {
                    ... on FotoGalerija {
                      id
                    }
                  }
                  link {
                    url
                  }
                  slika {
                    sourceUrl
                  }
                }
                kutijaslike6 {
                  naslov
                  galerija {
                    ... on FotoGalerija {
                      id
                    }
                  }
                  link {
                    url
                  }
                  slika {
                    sourceUrl
                  }
                }
                kutijaslike7 {
                  naslov
                  galerija {
                    ... on FotoGalerija {
                      id
                    }
                  }
                  link {
                    url
                  }
                  slika {
                    sourceUrl
                  }
                }
              	kutijaslike8 {
                  naslov
                  galerija {
                    ... on FotoGalerija {
                      id
                    }
                  }
                  link {
                    url
                  }
                  slika {
                    sourceUrl
                  }
                }
                kutijaslike9 {
                  naslov
                  galerija {
                    ... on FotoGalerija {
                      id
                    }
                  }
                  link {
                    url
                  }
                  slika {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }`

  const [data, setData] = useState([]);
  const [ucitano, setUcitano] = useState(false)

  const jsonToData = async (jsonData) => {
    let galerije = []
    for (let i = 1; i <= 9; i++) {
      let galerija = jsonData[`kutijaslike${i != 1 ? i : ''}`]
      if (galerija['galerija'] == null) {
        galerije.push({
          slika: galerija['slika']['sourceUrl'],
          naslov: galerija['naslov'],
          id: galerija['galerija'] = null,
        })
      } else {
        const fetchedData = await fetchGraphqlData(galerija['galerija']['id'])
        galerije.push(fetchedData)
      }
    }
    setData(galerije)
    setUcitano(true)
  }

  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => jsonToData(data['data']['pages']['nodes'][0]['pocetna']['galerija']));
  }, []);

  return <>
    <div className="galerija2-wrapper">
      <div className="naslov">
        <h1>Објектив(<b>ни</b>)</h1>
        <h2>Изрази и Одрази</h2>
      </div>
      <div className="slike">
        {ucitano == true ? <>
          <div className="slika s1">
            {data[0].id != null && <Link to={`/galerije/${data[0].id}`}></Link>}
            <img src={data[0].slika == null ? slika : data[0].slika}></img>
          </div>
          <div className="slika s2">
            {data[1].id != null && <Link to={`/galerije/${data[1].id}`}></Link>}
            <img src={data[1].slika == null ? slika : data[1].slika}></img>
          </div>
          <div className="slika s3">
            {data[2].id != null && <Link to={`/galerije/${data[2].id}`}></Link>}
            <img src={data[2].slika == null ? slika : data[2].slika}></img>
          </div>
          <div className="slika s4">
            {data[3].id != null && <Link to={`/galerije/${data[3].id}`}></Link>}
            <img src={data[3].slika == null ? slika : data[3].slika}></img>
          </div>
          <div className="slika s5">
            {data[4].id != null && <Link to={`/galerije/${data[4].id}`}></Link>}
            <img src={data[4].slika == null ? slika : data[4].slika}></img>
          </div>
          <div className="slika s6">
            {data[5].id != null && <Link to={`/galerije/${data[5].id}`}></Link>}
            <img src={data[5].slika == null ? slika : data[5].slika}></img>
          </div>
          <div className="slika s7">
            {data[6].id != null && <Link to={`/galerije/${data[6].id}`}></Link>}
            <img src={data[6].slika == null ? slika : data[6].slika}></img>
          </div>
          <div className="slika s8">
            {data[7].id != null && <Link to={`/galerije/${data[7].id}`}></Link>}
            <img src={data[7].slika == null ? slika : data[7].slika}></img>
          </div>
          <div className="slika s9">
            {data[8].id != null && <Link to={`/galerije/${data[8].id}`}></Link>}
            <img src={data[8].slika == null ? slika : data[8].slika}></img>
          </div>
          <div className="slika s10">
            <img src={kamera}></img>
          </div></> : <></>
        }

      </div>
      <div className="dekor">
        <div className="dekor1">
          <div className="krug k1 siv"></div>
          <div className="krug k7 siv"></div>
          <div className="krug k2 plav isprekidan"></div>
          <div className="krug k3 beo isprekidan"></div>
          <div className="krug k4 siv"></div>
          <div className="krug k5 plav isprekidan"></div>
          <Link to="/galerije" className="krug k6 siv">Више слика...</Link>
          <div className="krug k8 beo"></div>
          <div className="zvezda z1">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="plus p2">
            <div></div>
            <div></div>
          </div>
          <div className="plus p1">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Galerija2;