import '../../style.css';
import oglasna from '../../res/images/oglasna.jpg';
import obavestenje from '../../res/images/obavestenje.png'
import obavestenje_mob from '../../res/images/obavestenje-mob.png'
import cioda from '../../res/images/cioda.png'
import linkSlika from '../../res/images/link.png'
import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import apiEndpoint from '../../test/config';
import Oglas from './Oglas';

function napraviOglase(obavestenja) {
  let hue_rotacija = [135, 200, 0, 260]
  let oglasi = [];
  let n = 4;
  if (obavestenja.length < 4) n = obavestenja.length;
  for (let i = 0; i < n; i++) {
    let text = obavestenja[i]["acm_fields"]["naslov"];
    let datum = obavestenja[i]["date"].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join(".");
    let vazno = obavestenja[i]["acm_fields"]["vazno"];
    if (text.length > 170) text += '...';
    oglasi.push(
      <div id={vazno ? "vazno" : "nevazno"}>
        <img id='papir' src={obavestenje} alt={obavestenje_mob}></img>
        <p id='text'>{text}</p>
        <p id='datum'>{datum}</p>
        <img id='cioda' src={cioda} style={{ filter: 'hue-rotate(' + hue_rotacija[i] + 'deg)' }}></img>
      </div>
    )
  }
  return oglasi;
}

function OglasnaTabla() {
  const query = `
  {
    allObavestenja(first: 4) {
      nodes {
        ...ObavestenjaFields
      }
    }
  }
  
  fragment ObavestenjaFields on Obavestenja{
    naslov
    teloObavestenja
    vazno
    date
    fajl {
      mediaItemUrl
      title
    }
  }`

  const [obavestenja, setObavestenja] = useState([])
  const [linkovi, setLinkovi] = useState([])
  const [ucitano, setUcitano] = useState(false)

  const jsonToData = (jsonData) => {
    // let brzilinkovi = jsonData['pages']['edges'][0]['node']['pocetna']['brzilinkovi'];
    // let linkovi1 = []
    // for (let i = 1; i <= 3; i++) {
    //   let urlSlika = {
    //     slika: brzilinkovi[`kutija${i}`]['slika']['sourceUrl'],
    //     link: brzilinkovi[`kutija${i}`]['link']['url'],
    //   }
    //   linkovi1.push(urlSlika)
    // }

    let data = jsonData['allObavestenja']['nodes']
    let obavestenjaArr = data.map((obavestenje, index) => {
      return {
        tekst: obavestenje['teloObavestenja'],
        naslov: obavestenje['naslov'],
        vazno: obavestenje['vazno'],
        datum: obavestenje["date"].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join("."),
        fajlovi: obavestenje['fajl'] == null ? [] : obavestenje['fajl'].map((fajl) => {
          var extensions = fajl['mediaItemUrl'].match(/\.([0-9a-z]+)(?:[\?#]|$)/i)
          var fileExt = extensions.pop()
          return {
            ext: fileExt,
            naslov: fajl['title'],
            link: fajl['mediaItemUrl']
          }
        })
      }
    })
    //setLinkovi(linkovi1)
    setObavestenja(obavestenjaArr)
    setUcitano(true)
  }
  const navigate = useNavigate();
  useEffect(() => {
    '/obavestenja'
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => jsonToData(data['data']));
  }, []);

  return <>
    <div className='oglasna-tabla-wrapper'>
      {/* <img src={oglasna}></img> */}
      <div style={{ backgroundImage: `url("${oglasna}")` }} id='oglasi-wrapper'>
        <h1>Огласна табла</h1>
        <div className='hl'></div>
        <div id='oglasi'>
          {obavestenja.map((obavestenje, index) => {
            return <Oglas key={index} index={index} obavestenje={obavestenje}></Oglas>
          })}
        </div>

        <div id='sve-informacije-wrapper'>
          <button className="sve-informacije" onClick={() => {
            navigate("/obavestenja")
            window.scrollTo(0, 0)
          }}>
            Све информације...
          </button>
        </div>
      </div>
    </div>
    {/* <div className="oglasna-linkovi-wrapper">
      <div className='linkovi'>
        <img src={linkSlika}></img>
        {linkovi.length == 0 ? <div></div> : <>
          <div>
            <img src={linkovi[0].slika}></img>
            <a href={linkovi[0].link}></a>
          </div>
          <div>
            <img src={linkovi[1].slika}></img>
            <a href={linkovi[1].link}></a>
          </div>
          <div>
            <img src={linkovi[2].slika}></img>
            <a href={linkovi[2].link}></a>
          </div>
        </>
        }
      </div>
    </div> */}
  </>;
}

export default OglasnaTabla;