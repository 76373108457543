import { useEffect, useState } from "react";
import apiEndpoint from "../../test/config";
import { json } from "stream/consumers";

export default function NajavaDesavanje() {
    let query = `{
        pages(where: {name: "Pocetna"}) {
          nodes {
            pocetna {
              najave {
                pozadina
                najavafield1 {
                    bojadatuma
                    datumnajave
                    tekst
                }
                najavafield2 {
                    bojadatuma
                    tekst
                    datumnajave
                }
                najavafield3 {
                    bojadatuma
                    tekst
                    datumnajave
                }
                najavafield4 {
                    bojadatuma
                    datumnajave
                    tekst
                }
                backgroundimage {
                    sourceUrl
                  }
                }
            }
            }
        }
    }`
    const [najave, setNajave] = useState([])
    var colour = "#D9D9D9";
    var slika = null;
    const dataToVesti = (jsonData) => {
        jsonData = jsonData['najave']
        //console.log(jsonData)
        slika = jsonData['backgroundimage'] == null ? null : jsonData['backgroundimage']['sourceUrl']
        colour = jsonData['pozadina'] == null ? "#D9D9D9" : jsonData['pozadina']
        //console.log(slika)
        let najavaArr = []
        for (let i = 1; i <= 4; i++) {
            let kutija = jsonData[`najavafield${i}`]
            // console.log(kutija)
            najavaArr.push({
                bojaDatuma: kutija['bojadatuma'] == null ? "#07829d" : kutija['bojadatuma'],
                datumnajave: kutija['datumnajave'] == null ? null : kutija['datumnajave'].split("/").join("."),
                tekst: kutija['tekst'] == null ? null : kutija['tekst'],
                pozadina: colour,
                slika: slika,
            })
            // console.log(najavaArr[i - 1])
        }
        setNajave(najavaArr)
    }
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => dataToVesti(data['data']['pages']['nodes'][0]['pocetna']));
    }, []);
    const mesec = (datum) => {
        switch (datum) {
            case '01':
                return 'Јануар'
            case '02':
                return 'Фебруар'
            case '03':
                return 'Март'
            case '04':
                return 'Април'
            case '05':
                return 'Мај'
            case '06':
                return 'Јун'
            case '07':
                return 'Јул'
            case '08':
                return 'Август'
            case '09':
                return 'Септембар'
            case '10':
                return 'Октобар'
            case '11':
                return 'Новембар'
            default:
                return 'Децембар'
        }
    }
    const generatorNajave = (najava) => {
        if (najava['datumnajave'] == null || najava['tekst'] == null) {
            return <>
            </>

        }
        // console.log(najava['bojaDatuma'])
        const bojaKomponente = najava['bojaDatuma'] == null ? "#07829d" : najava['bojaDatuma']
        return <>
            <div className="desavanje">
                <div className="levo" style={{ background: bojaKomponente }}>
                    <h1 className="dan">{najava['datumnajave'].substring(0, 2) + "."}</h1>
                    <h3 className="mesec">{mesec(najava['datumnajave'].substring(3, 5))}</h3>
                </div>
                <div className="desno">
                    <div dangerouslySetInnerHTML={{ __html: najava['tekst'] }} className="tekst"></div>
                </div>
            </div >
        </>
    }
    //console.log(slika)
    return <>
        {najave.length > 0 && <div className="najava-wrapper">
            <div className="najava" style={najave[0].slika != null ? { backgroundImage: `url("${najave[0].slika}")`, backgroundSize: 'cover' } : { background: najave[0].pozadina }}>
                <h1 className="naslov">НАЈАВЕ дешавања!</h1>
                <div className="najave">
                    {
                        najave.map(el => (
                            generatorNajave(el)
                        ))
                    }
                </div>
            </div>
        </div>}
    </>
}