import '../../style.css';
import it_smer from '../../res/images/smer-it.png';
import prirodni_smer from '../../res/images/smer-prirodni.png';
import drustveni_smer from '../../res/images/smer-drustveni.png';
import { useEffect, useState } from 'react';
import apiEndpoint from '../../test/config';
import { Link } from 'react-router-dom';
const plavaSlika = "https://htmlcolorcodes.com/assets/images/colors/bright-blue-color-solid-background-1920x1080.png"

function Smerovi() {
  let query = `{
    pages(where: {name: "Pocetna"}) {
      edges {
        node {
          pocetna {
            smerovi {
              smer1 {
                link {
                  url
                }
                nazivsmera
                slika {
                  sourceUrl
                }
              }
              smer2 {
                link {
                  url
                }
                nazivsmera
                slika {
                  sourceUrl
                }
              }
              smer3 {
                link {
                  url
                }
                nazivsmera
                slika {
                  sourceUrl
                }
              }
              naslov
              podtekst
              boje {
                bojanaslova
                bojapodteksta
                slikapozadine {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }`;

  const [data, setData] = useState({});

  const jsonToData = (jsonData) => {
    let smeroviJson = jsonData['pages']['edges'][0]['node']['pocetna']['smerovi'];
    let smeroviArr = []
    for (let i = 1; i <= 3; i++) {
      let smer = {
        naziv: smeroviJson[`smer${i}`]['nazivsmera'],
        slika: smeroviJson[`smer${i}`]['slika']['sourceUrl'],
        link: smeroviJson[`smer${i}`]['link']['url'],
      }
      smeroviArr.push(smer)
    }
    let smerovi = {
      naslov: smeroviJson['naslov'],
      tekst: smeroviJson['podtekst'],
      bojaNaslova: smeroviJson['boje']['bojanaslova'],
      bojaTeksta: smeroviJson['boje']['bojapodteksta'],
      slikaPozadaine: smeroviJson['boje']['slikapozadine'] == null ? plavaSlika : smeroviJson['boje']['slikapozadine']['sourceUrl'],
      podatci: smeroviArr
    }
    setData(smerovi);
  }

  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => jsonToData(data['data']));
  }, []);

  try {
    let smerovi = data['podatci']
    return <>
      <div className='smerovi' style={{ backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('${data['slikaPozadaine']}')` }}>
        <div className='smerovi-w65p'>
          <h1 style={{ color: data['bojaNaslova'] }}>{data['naslov']}</h1>
          <div className="hl"></div>
          <p style={{ color: data['bojaTeksta'] }}>{data['tekst']}</p>
        </div>
        <div className='smerovi-slike-wrapper'>
          <Link to={smerovi[0]['link']}>
            <div id="smer1" className='smer-slika'>
              <section>
                <img src={smerovi[0]['slika']}></img>
              </section>
              <h1> {smerovi[0]['naziv']} </h1>
            </div>
          </Link>
          <Link to={smerovi[1]['link']}>
            <div id="smer2" className='smer-slika'>
              <section>
                <img src={smerovi[1]['slika']}></img>
              </section>
              <h1> {smerovi[1]['naziv']} </h1>
            </div>
          </Link>
          <Link to={smerovi[2]['link']}>
            <div id="smer3" className='smer-slika'>
              <section>
                <img src={smerovi[2]['slika']}></img>
              </section>
              <h1> {smerovi[2]['naziv']} </h1>
            </div>
          </Link>
        </div>
      </div>
      <div className="smerovi-kraj"></div>
    </>;
  }
  catch (error) {
    return <></>
  }

}

export default Smerovi;